import { Modal } from "@app/components/modals/modal";
import { Popover } from "@app/components/popovers/popover";
import { commonMessages } from "@app/constants/common-messages";
import {
	type ValidationErrors,
	validationErrorOptions,
} from "@app/constants/validation-errors";
import { Button } from "@app/controls/button";
import { Dropdown } from "@app/controls/dropdown";
import { Input } from "@app/controls/input";
import type { RootState } from "@app/redux";
import { Transition } from "@headlessui/react";
import { Formik } from "formik";
import { memo } from "react";
import { useSelector } from "react-redux";
import type { FormValues, ViewProperties } from "./properties";
import "./withdraw-amount-modal.css";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { FaInfoCircle } from "react-icons/fa";

const accountTypeMap = {
	bond: "Bond Account",
	cheque: "Current/Cheque Account",
	credit: "Credit Card Account",
	savings: "Savings Account",
	subscription: "Subscription Account",
	transmission: "Transmission Account",
};

const isWithdrawalTypeDisabled = true;

export const WithdrawAmountModalView = memo((props: ViewProperties) => {
	const isMobile = useMediaQuery();
	const { language } = useSelector((state: RootState) => state.language);

	const getErrors = () => {
		const errors = props.errors || [];

		return (
			<Transition
				show={errors.length > 0}
				enter="transition ease-out duration-300"
				enterFrom="opacity-0 translate-y-5"
				dangerouslySetInnerHTML={{
					__html: `<p>${errors.join("</p><p>")}</p>`,
				}}
			/>
		);
	};

	const renderTooltip = (footnoteMessage: string) => {
		return (
			<Popover
				toggleContent={
					<FaInfoCircle className={"opacity-50 withdraw-icon"} size="18px" />
				}
				popperContent={
					<div
						className="font-primary-regular leading-5 -m-2.5 p-2.5 withdraw-footnote"
						dangerouslySetInnerHTML={{
							__html: footnoteMessage,
						}}
					/>
				}
				showArrow={true}
				showOnHover={!isMobile}
				placement={"top-end"}
				offset={[80, 16]}
			/>
		);
	};

	const withdrawAllItems = [
		{
			label: "Yes",
			value: true,
		},
		{
			label: "No",
			value: false,
		},
	];

	const withdrawTypes = [
		{
			label: "Standard (Fee: R20.00)",
			value: "Standard (Fee: R20.00)",
		},
		{
			label: "STP/Fast (Fee: R60.00)",
			value: "STP",
		},
	];

	const defaultValues: FormValues = {
		amount: "",
		withdrawAll: false,
		withdrawalType: "",
	};

	const getError = (show: boolean) => {
		const areErrors =
			show ||
			(props.mappedErrors !== undefined &&
				Object.entries(props.mappedErrors).find((x) => x[1] !== undefined) !==
					undefined);

		return (
			<div className="error pb-5 flex justify-center">
				<span>
					<Transition
						show={areErrors}
						enter="transition ease-out duration-300"
						enterFrom="opacity-0 translate-y-5"
						leave="transition ease-out duration-300"
						leaveTo="opacity-0 -translate-y-5"
					>
						<p>There are errors in your submission. Please see above.</p>
					</Transition>
				</span>
			</div>
		);
	};

	const getFieldErrorContent = (fieldName: string, localError?: string) => {
		const errors = getFieldErrors(fieldName, localError);
		const showCondition = isFieldInError(fieldName, localError);

		return (
			<Transition
				show={showCondition}
				enter="transition ease-out duration-300"
				leave="transition east-out duration-300"
				enterFrom="opacity-0 translate-y-5"
				enterTo="opacity-100 translate-y-0"
				leaveTo="opacity-0 -translate-y-5"
			>
				{errors && (
					<div className="flex flex-col gap-y-2">
						{Array.isArray(errors) ? (
							<div
								className="font-primary-regular withdraw-amount-error"
								dangerouslySetInnerHTML={{
									__html: `<p>${errors.join("</p><p>")}</p>`,
								}}
							/>
						) : (
							<div className="font-primary-regular withdraw-amount-error">
								{typeof errors === "string"
									? errors
									: "An unexpected error occured"}
							</div>
						)}
					</div>
				)}
			</Transition>
		);
	};

	const isFieldInError = (
		key: string,
		localError?: string,
	): boolean | undefined => {
		if (localError) {
			return true;
		}

		let result = false;

		if (props.mappedErrors) result = (props.mappedErrors[key] ?? []).length > 0;

		if (!result) return undefined;

		return result;
	};

	const getFieldErrors = (
		key: string,
		localError?: string,
	): string[] | undefined => {
		if (localError) {
			return [mapControlError(localError)];
		}

		let result: string[] = [];

		if (props.mappedErrors) {
			result = props.mappedErrors[key] ?? [];
		}

		if (result.length > 0) {
			return result;
		}

		return undefined;
	};

	const mapControlError = (error: string) => {
		const validationError = error as ValidationErrors;

		if (validationErrorOptions.includes(validationError)) {
			if (validationError && validationError.length > 0) {
				return commonMessages[language].validationError[validationError];
			}
			return commonMessages[language].validationError.required;
		}
		return commonMessages[language].validationError.required;
	};

	return (
		<Modal
			className="withdraw-amount-modal h-full"
			open={props.open}
			onClose={props.onClose}
		>
			<div className="withdraw-amount-container">
				<Formik initialValues={defaultValues} onSubmit={props.onFormSubmit}>
					{({ handleSubmit, validateForm, errors }) => (
						<>
							<h1 className="font-primary-bold mb-3">Confirm withdrawal</h1>
							<div className="withdraw-account-details text-lg mb-9">
								<p>
									<span>Bank: </span>
									{props.bankName}
								</p>
								<p>
									<span>Branch Code: </span>
									{props.branchCode}
								</p>
								<p>
									<span>Account Type: </span>
									{accountTypeMap[props.accountType]}
								</p>
								<p>
									<span>Account Number: </span>
									{props.accountNumber}
								</p>
							</div>
							<label className="api-error mb-3">{getErrors()}</label>
							<label className="mb-2">Withdraw all</label>
							{getFieldErrorContent("withdrawAll", errors.withdrawAll)}
							<Dropdown
								className="mb-3.5 withdraw-dropdown"
								items={withdrawAllItems}
								header="Withdraw all"
								theme={"primary"}
								name="withdrawAll"
								onSelect={(value) => {
									props.onWithdrawAllDropdownSelect(value);
								}}
								required
								useFormik
								defaultValue={defaultValues.withdrawAll}
							/>

							{!props.withdrawAll && (
								<>
									<label className="mb-2">Amount</label>
									{getFieldErrorContent("amount", errors.amount)}
									{props.noInput ? undefined : (
										<Input
											className="mb-3.5 amount-input"
											maxValue={props.maxValue}
											minValue={props.minValue}
											name="amount"
											placeholder="0.00"
											theme="secondary"
											title="Please enter the amount you wish to withdraw"
											type="currency"
											required
											useFormik
											validateOnChange={props.validate}
											onChange={props.onClearErrors}
										/>
									)}
								</>
							)}
							<div className="flex flex-row items-center withdraw-tooltip-container">
								<label className="mr-2">
									{isWithdrawalTypeDisabled
										? "Capitec Withdrawal Fee"
										: "Withdrawal Type"}
								</label>
								{renderTooltip(
									isWithdrawalTypeDisabled
										? "This processing fee is charged by Capitec. We do not charge any fees/commission on top of this."
										: "A Standard withdrawal will typically take 3 business days to reflect in your account, whereas a STP/Fast withdrawal typically takes 1-2 business days.",
								)}
							</div>
							{getFieldErrorContent("withdrawalType", errors.withdrawalType)}
							{isWithdrawalTypeDisabled ? (
								<h5 className={"account-holder-name text-lg"}>ZAR 20.00</h5>
							) : (
								<Dropdown
									className="mb-3.5 mt-2 withdraw-dropdown"
									theme={"primary"}
									name="withdrawalType"
									items={withdrawTypes}
									onSelect={(value) => {
										props.onWithdrawTypeDropdownSelect(value);
									}}
									header="Select a withdrawal type"
									required
									useFormik
									defaultValue={defaultValues.withdrawalType}
									validateOnChange={props.validate}
								/>
							)}
							{getError(Object.keys(errors).length > 0)}
							<Button
								className="submit font-primary-medium"
								theme="primary"
								disabled={props.disabled}
								onClick={async () =>
									props.onValidate(await validateForm(), handleSubmit)
								}
							>
								Submit
							</Button>
						</>
					)}
				</Formik>
			</div>
		</Modal>
	);
});
