import { paths } from "@app/constants/paths";

export const removeInvalidSearchparams = (path: string) => {
	const invalidQueries = ["auth-token"];

	try {
		const url = new URL(path, window.location.origin);

		for (const query of invalidQueries) {
			url.searchParams.delete(query);
		}

		return url.pathname + url.search;
	} catch (error) {
		console.error("Invalid redirect url:", error);
		return path;
	}
};

export const getRedirectUrl = () => {
	const redirectUrl = window.localStorage.getItem("redirect-url");

	const isValidRedirectUrl = [
		paths.clients,
		paths.dashboard,
		paths.trades,
	].some((path) => redirectUrl?.startsWith(path));

	if (redirectUrl && isValidRedirectUrl) {
		const cleanedUrl = removeInvalidSearchparams(redirectUrl);
		window.localStorage.removeItem("redirect-url");
		return cleanedUrl;
	}

	return null;
};
