import type { RootState } from "@app/redux";
import { authorizedApi } from "@app/services";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";

type Spread = {
	datetime: string;
	exchange_rate: number;
	local_price: number;
	offshore_price: number;
	spread: number;
};

type MappedSpread = {
	datetime: string;
	spread: number;
	localPrice: number;
	offshorePrice: number;
	exchangeRate: number;
};

const mapToSpread = (current: Spread): MappedSpread => ({
	datetime: current.datetime,
	spread: current.spread,
	localPrice: current.local_price,
	offshorePrice: current.offshore_price,
	exchangeRate: current.exchange_rate,
});

const getMostRecentSpread = async (period: number) => {
	const { data } = await authorizedApi.get<Spread>(
		`market-conditions/most_recent/?period=${period}`,
	);
	return mapToSpread(data);
};

export const useHistorySpread = (period?: number) => {
	const { userIsAuthenticated } = useSelector(
		(rootState: RootState) => rootState.auth,
	);
	const arbitragerState = useSelector(
		(rootState: RootState) => rootState.arbitrager,
	);
	const { currentPeriod } = arbitragerState ?? {};

	const { data, error, mutate, isLoading } = useSWR<Array<Spread>>(
		period && userIsAuthenticated
			? `/market-conditions/history/?period=${period}`
			: null,
	);
	const [historySpread, setHistorySpread] = useState<MappedSpread[]>([]);

	useEffect(() => {
		if (data) {
			setHistorySpread(data.map(mapToSpread));
		}
	}, [data]);

	useEffect(() => {
		if (!isLoading && currentPeriod) {
			const timeout = setInterval(() => {
				const updateSpread = async () => {
					const spread = await getMostRecentSpread(currentPeriod.days);
					setHistorySpread((prev) => [...prev, spread]);
				};
				updateSpread();
			}, currentPeriod?.interval.timedelta * 1000);
			return () => {
				clearInterval(timeout);
			};
		}
	}, [currentPeriod, isLoading]);

	return {
		historySpread,
		error,
		mutate,
		isLoading,
	};
};
