export type FormatNumberOptions = {
	currencyCode?: string;
	decimalPlaces?: number;
	formatUnit?: boolean;
	groupSeparator?: string;
	isPercentage?: boolean;
	padSeparator?: string;
	padStart?: number;
	maxSignificantDigits?: number;
	removeTrailingZeros?: boolean;
};

const unitSuffixes = ["", "k", "m", "b", "t"];

export const formatNumber = (
	value: string | number,
	{
		currencyCode = "",
		decimalPlaces = 0,
		formatUnit = false,
		groupSeparator = "",
		isPercentage = false,
		padSeparator = "0",
		padStart = 0,
		maxSignificantDigits,
		removeTrailingZeros = false,
	}: FormatNumberOptions = {},
): string => {
	const valueAsNumber =
		typeof value === "string"
			? Number.parseFloat(value.replace(/[^0-9.-]/g, ""))
			: value;

	if (Number.isNaN(valueAsNumber)) {
		return "";
	}

	let formattedValue = valueAsNumber;
	let unitSuffix = "";

	if (formatUnit && formattedValue !== 0) {
		const unit = Math.min(
			Math.trunc(Math.log10(Math.abs(formattedValue)) / 3),
			unitSuffixes.length - 1,
		);
		unitSuffix = unitSuffixes[unit];
		formattedValue /= Math.pow(10, unit * 3);
	}

	// Apply maxSignificantDigits if specified
	if (maxSignificantDigits !== undefined) {
		const absValue = Math.abs(formattedValue);
		if (absValue !== 0) {
			// Avoid log10(0)
			const magnitude = Math.floor(Math.log10(absValue));
			const scale = Math.pow(10, magnitude - (maxSignificantDigits - 1));
			formattedValue = Math.round(formattedValue / scale) * scale;
		}
	}

	const absValue = Math.abs(formattedValue);
	let output = absValue.toFixed(decimalPlaces);

	if (removeTrailingZeros && decimalPlaces > 0) {
		output = output.replace(/\.?0+$/, "");
	}

	if (groupSeparator) {
		const parts = output.split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, groupSeparator);
		output = parts.join(".");
	}

	if (padStart > 0) {
		const [integerPart, decimalPart] = output.split(".");
		const paddedIntegerPart = integerPart.padStart(padStart, padSeparator);
		output = decimalPart
			? `${paddedIntegerPart}.${decimalPart}`
			: paddedIntegerPart;
	}

	const result = `${formattedValue < 0 ? "-" : ""}${currencyCode}${output}${unitSuffix}${isPercentage ? "%" : ""}`;

	return result;
};
