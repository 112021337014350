import { Paginator } from "@app/controls/paginator";
import React, { type ReactNode } from "react";
import type { Properties } from "./properties";
import "./table.css";

import { Dropdown } from "@app/controls/dropdown";
import { useMediaQuery } from "@app/hooks/use-media-query";
import styles from "./table-view-module.module.css";

export interface Cell {
	className?: string;
	alignment?: "center" | "left";
	content: ReactNode;
	onClick?: () => void;
}

export type Row = {
	cells: Cell[];
	onClick?: () => void;
};

export const Table = React.memo(
	(props: {
		className?: string;
		headers: Row;
		data: Row[];
		pagination?: boolean;
		currentPage?: number;
		totalPages?: number;
		count?: number;
		blockSize?: number;
		onNavigatePage?: (page: number) => void;
		onBlockSizeChange?: (blockSize: number) => void;
	}) => {
		const isMobile = useMediaQuery();
		const getClassNames = () => {
			const classesToUse = ["flex flex-col flex-1"];
			if (props.className) classesToUse.push(props.className);
			return classesToUse.join(" ");
		};

		const buildRow = (row: Row, index: number, isHeader?: boolean) => {
			const classNames = [];

			if (row.onClick) classNames.push("clickable");

			if (isHeader) classNames.push("border-t-0");

			return (
				<tr onClick={row.onClick} key={index} className={classNames.join(" ")}>
					{row.cells.map((x, cellIndex) => buildCell(x, cellIndex, isHeader))}
				</tr>
			);
		};

		const buildCell = (cell: Cell, index: number, isHeader?: boolean) => {
			let classesToUse = ["text-left px-4 py-4 lg:px-14 lg:py-9"];

			if (cell.alignment === "left")
				classesToUse = ["text-left pr-10 py-4 lg:pr-28 lg:py-9"];

			if (cell.className) classesToUse.push(cell.className);

			if (isHeader)
				return (
					<th key={index} className={classesToUse.join(" ")}>
						{cell.content}
					</th>
				);

			return (
				<td
					key={index}
					onClick={cell.onClick}
					className={classesToUse.join(" ")}
				>
					{cell.content}
				</td>
			);
		};

		return (
			<div className={getClassNames()}>
				<div className="overflow-auto">
					<table className="w-full mb-2" cellPadding={0}>
						<thead>{buildRow(props.headers, 0, true)}</thead>
						<tbody>
							{props.data.map((x, rowIndex) => buildRow(x, rowIndex))}
						</tbody>
					</table>
				</div>
				{props.pagination && (
					<div className={styles.paginator}>
						{!isMobile && (
							<>
								{props.onBlockSizeChange &&
								props.blockSize &&
								props.currentPage ? (
									<p className={styles.pageInfo}>
										Showing results{" "}
										{(props.currentPage - 1) * props.blockSize + 1}-
										{Math.min(
											props.currentPage * props.blockSize,
											props?.count ?? 0,
										)}{" "}
										of {props?.count ?? 0}
									</p>
								) : (
									<div />
								)}
							</>
						)}

						<Paginator
							blockSize={props.blockSize}
							currentPage={props.currentPage || 1}
							showFixedFirstPage
							showFixedLastPage
							totalPages={props.totalPages || 1}
							onNavigatePage={(page) => props.onNavigatePage?.(page)}
						/>

						{isMobile &&
							props.onBlockSizeChange &&
							props.blockSize &&
							props.currentPage && (
								<p className={styles.pageInfo}>
									Showing results{" "}
									{(props.currentPage - 1) * props.blockSize + 1}-
									{Math.min(
										props.currentPage * props.blockSize,
										props?.count ?? 0,
									)}{" "}
									of {props?.count ?? 0}
								</p>
							)}

						{props.onBlockSizeChange ? (
							<div className={styles.pageSize}>
								<p>Rows per page:</p>
								<Dropdown
									className={styles.dropdown}
									items={[
										{
											label: "10",
											value: 10,
										},
										{
											label: "25",
											value: 25,
										},
										{
											label: "50",
											value: 50,
										},
										{
											label: "100",
											value: 100,
										},
									]}
									theme="primary"
									name="blockSize"
									onSelect={props.onBlockSizeChange}
									defaultValue={props.blockSize}
								/>
							</div>
						) : (
							<div />
						)}
					</div>
				)}
			</div>
		);
	},
);
