import { Modal } from "@app/components/modals/modal";
import { Button } from "@app/controls/button";
import { memo } from "react";
import "./account-deletion-modal.css";
import type { AccountType } from "@app/entities";

const accountType = {
	bond: "Bond Account",
	cheque: "Current/Cheque Account",
	credit: "Credit Card Account",
	savings: "Savings Account",
	subscription: "Subscription Account",
	transmission: "Transmission Account",
};

export const AccountDeletionModalView = memo(
	(props: {
		open: boolean;
		bankId: number;
		bankName: string;
		branchCode: string;
		accountNumber: string;
		accountType: AccountType;
		onClose: () => void;
		disabled?: boolean;
		onDelete: (bankId: number) => void;
	}) => {
		return (
			<Modal
				className={"account-deletion-modal"}
				open={props.open}
				onClose={props.onClose}
			>
				<h1 className="font-bold text-2xl mb-8">Confirm account deletion</h1>
				<div className="account-details text-lg mb-9">
					<p>
						<span>Bank: </span>
						{props.bankName}
					</p>
					<p>
						<span>Branch Code: </span>
						{props.branchCode}
					</p>
					<p>
						<span>Account Number: </span>
						{props.accountNumber}
					</p>
					<p className="mb-9">
						<span>Account Type: </span>
						{accountType[props.accountType]}
					</p>
				</div>
				<Button
					disabled={props.disabled}
					onClick={() => {
						props.onDelete(props.bankId);
					}}
					className={"account-deletion-button"}
					type="button"
					theme="primary"
				>
					Delete account
				</Button>
			</Modal>
		);
	},
);
