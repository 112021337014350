export const dateFormats = {
	iso8601: "YYYY-MM-DD",
	iso8601withTime: "YYYY-MM-DD HH:mm:ss",
	time: "HH:mm:ss",
	time24HoursMinutes: "HH:mm",
	reverseIso8601: "DD-MM-YYYY",
	reverseIso8601Slash: "DD/MM/YYYY",
	reverseIso8601ShortSlash: "DD/MM/YY",
	dateShort: "D MMM",
	british: "D MMM YYYY",
	fullMonthFullYear: "MMMM, YYYY",
};
