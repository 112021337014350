import type {
	Chart,
	PeriodSettingsResponse,
	SpreadResponse,
} from "@app/entities";
import { concatenateArrays } from "@app/utils/concatenate-arrays";
import { authorizedApi } from "./api";
import type {
	GenericFailureResponse,
	GenericResponse,
	GetSpreadResponse,
} from "./models";
import type { GetPeriodSettingsResponse } from "./models/arbitrager";

function mapToSettings(
	value: GetPeriodSettingsResponse,
): PeriodSettingsResponse {
	return {
		marketChart: {
			default: +value.market_chart.default,
			periods: value.market_chart.periods.map((x) => {
				const key = Object.keys(x)[0];
				return {
					days: +key,
					display: x[key].display,
					interval: {
						string: x[key].interval.string,
						timedelta: +x[key].interval.timedelta,
					},
				};
			}),
		},
		statsChart: Object.keys(value.stats_chart).map<Chart>((x) => {
			return {
				chartType: value.stats_chart[x].chart_type,
				label: value.stats_chart[x].label,
				slug: x,
			};
		}),
	};
}

function mapToSpread(value: GetSpreadResponse): SpreadResponse {
	return {
		datetime: value.datetime,
		exchangeRate: value.exchange_rate,
		localPrice: value.local_price,
		offshorePrice: value.offshore_price,
		spread: value.spread,
	};
}

export async function listPeriodSettings(): Promise<
	PeriodSettingsResponse | GenericResponse
> {
	try {
		const { data } =
			await authorizedApi.get<GetPeriodSettingsResponse>("settings/");

		return mapToSettings(data);
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const reasons = concatenateArrays(error.genericErrors, [
			error.detail ?? "",
		]);

		return {
			reasons: reasons,
		};
	}
}

export async function getHistorySpread(
	period = 1,
): Promise<SpreadResponse[] | GenericResponse> {
	try {
		const { data } = await authorizedApi.get<GetSpreadResponse[]>(
			`market-conditions/history/?period=${period}`,
		);

		return data.map(mapToSpread);
	} catch (exception: any) {
		const error = exception?.data as GenericFailureResponse;
		const reasons = concatenateArrays(error?.genericErrors, [
			error?.detail ?? "",
		]);

		return {
			reasons: reasons,
		};
	}
}

export async function getMostRecentSpread(
	period = 1,
): Promise<SpreadResponse | GenericResponse> {
	try {
		const { data } = await authorizedApi.get<GetSpreadResponse>(
			`market-conditions/most_recent/?period=${period}`,
		);

		return mapToSpread(data);
	} catch (exception: any) {
		const error = exception?.data as GenericFailureResponse;

		const reasons = concatenateArrays(error?.genericErrors, [
			error?.detail ?? "",
		]);

		return {
			reasons: reasons,
		};
	}
}
