import type {
	Period,
	PeriodSettingsResponse,
	SpreadResponse,
} from "@app/entities";
import { apiArbitrager } from "@app/services";
import { parseError } from "@app/utils/parse-error";
import type { AppThunk } from "..";
import {
	appendMostRecentSpread,
	setCurrentPeriod,
	setHistorySpread,
	setHistorySpreadError,
	setHistorySpreadLoading,
	setPeriodSettings,
	setPeriodSettingsError,
	setPeriodSettingsLoading,
} from "./arbitrager-slice";

export const listPeriodSettingsAction =
	(
		callback?: (response?: PeriodSettingsResponse | string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		var results: PeriodSettingsResponse | string[] | undefined = undefined;

		try {
			dispatch(setPeriodSettingsLoading(true));
			dispatch(setPeriodSettingsError(null));

			const response = await apiArbitrager.listPeriodSettings();

			if ("reasons" in response) {
				dispatch(setPeriodSettingsError(response.reasons));
				results = response.reasons;
			} else {
				dispatch(setPeriodSettings(response));
				results = response;
			}
		} catch (error: any) {
			const reasons = [parseError(error)];

			results = reasons;

			dispatch(setPeriodSettingsError(reasons));
		} finally {
			if (callback) {
				callback(results);
			}
			dispatch(setPeriodSettingsLoading(false));
		}
	};

export const getHistorySpreadAction =
	(
		period?: number,
		callback?: (response?: SpreadResponse[] | string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		let results: SpreadResponse[] | string[] | undefined;

		try {
			dispatch(setHistorySpreadLoading(true));
			dispatch(setHistorySpreadError(null));

			const response = await apiArbitrager.getHistorySpread(period);

			if ("reasons" in response) {
				dispatch(setHistorySpreadError(response.reasons));
				results = response.reasons;
			} else {
				dispatch(setHistorySpread(response));
				results = response;
			}
		} catch (error: any) {
			const reasons = [parseError(error)];

			results = reasons;

			dispatch(setHistorySpreadError(reasons));
		} finally {
			if (callback) {
				callback(results);
			}

			dispatch(setHistorySpreadLoading(false));
		}
	};

export const getMostRecentSpreadAction =
	(
		period?: number,
		callback?: (response?: SpreadResponse | string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		let results: SpreadResponse | string[] | undefined = undefined;

		try {
			dispatch(setHistorySpreadLoading(true));
			dispatch(setHistorySpreadError(null));

			const response = await apiArbitrager.getMostRecentSpread(period);

			if ("reasons" in response) {
				dispatch(setHistorySpreadError(response.reasons));
				results = response.reasons;
			} else {
				dispatch(appendMostRecentSpread(response));
				results = response;
			}
		} catch (error: any) {
			const reasons = [parseError(error)];
			results = reasons;

			dispatch(setHistorySpreadError(reasons));
		} finally {
			if (callback) {
				callback(results);
			}
			dispatch(setHistorySpreadLoading(false));
		}
	};

export const setCurrentPeriodAction =
	(period: Period, callback?: () => void): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(setCurrentPeriod(period));
		} finally {
			if (callback) {
				callback();
			}
		}
	};
