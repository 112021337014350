import { Button } from "@app/controls/button";
import type { RootState } from "@app/redux";
import React from "react";
import { useSelector } from "react-redux";
import { Modal } from "../modal";
import "./has-account-modal.css";

export const HasAccountModal = React.memo(
	(props: {
		open: boolean;
		onClose: () => void;
		onLogin: () => void;
	}) => {
		const { language } = useSelector((state: RootState) => state.language);

		return (
			<Modal open={props.open} onClose={props.onClose}>
				<h1 className="font-primary-bold mb-8 has-account-modal-heading">
					Account already exists
				</h1>
				<div className="flex flex-col mb-9 has-account-modal-text">
					<div>An account with matching credentials already exists.</div>
					<div>Please log in.</div>
				</div>
				<Button
					className="py-3 w-full uppercase font-primary-medium select-none"
					theme="primary"
					onClick={props.onLogin}
				>
					LOGIN
				</Button>
			</Modal>
		);
	},
);
