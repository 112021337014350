import { paths } from "@app/constants/paths";
import { useGetCycleStatement } from "@app/helpers";
import { useMediaQuery } from "@app/hooks/use-media-query";
import type { RootState } from "@app/redux";
import { downloadFile } from "@app/utils/download-file";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SummaryOfTradesCardView } from "./summary-of-trades-card-view";

export interface Trade {
	id: number;
	amount: string;
	netReturn: string;
	netProfit: string;
	startDate: string;
}

export const SummaryOfTradesCard = (props: {
	className?: string;
	data: Trade[];
}) => {
	const isMobile = useMediaQuery();
	const navigate = useNavigate();
	const [getCycleStatement] = useGetCycleStatement();

	const { cyclesError } = useSelector(
		(rootState: RootState) => rootState.clients,
	);

	const [showErrors, setShowErrors] = useState(false);

	const getTradeStatement = (
		id: number,
		callback: (contentType: string, data: string, fileName?: string) => void,
	) => {
		getCycleStatement(id, (response) => {
			if (
				response &&
				!Array.isArray(response) &&
				response.contentType &&
				response.data
			) {
				callback(response.contentType, response.data, response.fileName);
			} else {
				setShowErrors(true);
			}
		});
	};

	const onClearErrors = () => {
		setShowErrors(false);
	};

	const onDownloadTradeStatement = (id: number) => {
		getTradeStatement(id, (contentType, data, fileName) =>
			downloadFile(contentType, data, fileName || new Date().toLocaleString()),
		);
	};

	const onViewAll = () => {
		navigate(paths.trades);
	};

	const viewProps = {
		...props,
		errors: cyclesError?.join(" "),
		onClearErrors: onClearErrors,
		onDownloadTradeStatement: onDownloadTradeStatement,
		onViewAll: onViewAll,
		showErrors: showErrors,
	};

	return <SummaryOfTradesCardView {...viewProps} mobile={isMobile} />;
};
