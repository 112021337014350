import { CustomLoader } from "@app/components/custom-loader";
import { clampNumber } from "@app/utils/clamp-number";
import { getColourValue } from "@app/utils/get-colour-value";
import {
	BarElement,
	CategoryScale,
	type Chart,
	Chart as ChartJS,
	type ChartOptions,
	Filler,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	type ScriptableContext,
	Title,
	Tooltip,
} from "chart.js";
import React, { useMemo, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import "./graphing.css";
import { formatChartLabel } from "../spread-graph-card/format-chart-label";
import type { TrackerState } from "./models/tracker-state";
import type { Properties } from "./properties";

ChartJS.register(
	BarElement,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
);

export const GraphingView = (props: Properties) => {
	const generateLineGraphGradients = (context: ScriptableContext<"line">) => {
		const gradientEffect = 0.92;
		const ctx = context.chart.ctx;

		const gradient = ctx.createLinearGradient(
			0,
			0,
			0,
			context.chart.height * gradientEffect,
		);
		gradient.addColorStop(0, getColourValue("--teal-500"));
		gradient.addColorStop(1, getColourValue("--teal-transparent"));
		return gradient;
	};

	const generateBarGraphGradients = (
		context: ScriptableContext<"bar">,
		data: number[],
	) => {
		if (!context.chart.chartArea) return [];

		const gradients: CanvasGradient[] = [];
		const maxDataPoint = Math.max(...data); //Maximum value for the graph (takes up 100% of the graph)

		// determine height of chart area
		// Unsure why .top needed to be added back in, but it makes a large difference
		const height = context.chart.chartArea.height + context.chart.chartArea.top;

		data.forEach((x) => {
			//CC 2022-06-07: Explanation for the colouring of individual gradients in a bar graph
			// Chart js tool goes from top to bottom in terms of x/y co-ordinates. So to get the gradient
			// I have to calculate first the space the graph is not taking up (nullspace)
			// Then use this percentage to get the size in pixels of the null space from the graph
			// I can then use this to determine the start point of the gradient (the end of the nullspace) and
			// the end point of the gradient using the percentage modifier (gradientEffect)
			const nullSpacePercentage =
				(maxDataPoint - x) / (maxDataPoint + Number.EPSILON); //% between top of container and individual bar graph
			const nullSpaceSize = nullSpacePercentage * height; // pixel value of the above
			const startPoint = nullSpaceSize;
			const endPoint = height;

			const ctx = context.chart.ctx;
			const gradient = ctx.createLinearGradient(0, startPoint, 0, endPoint);

			gradient.addColorStop(0, getColourValue("--teal-500"));

			const difference = endPoint - startPoint;

			// Add secondary teal gradient point to ensure it doesn't fade too out soon
			// Scales based on size of bar in pixels
			if (difference >= 50) {
				gradient.addColorStop(0.2, getColourValue("--teal-500"));
			} else {
				gradient.addColorStop(0.1, getColourValue("--teal-500"));
			}

			// Removes fading completely when the difference is less than 1 pixel
			if (difference > 1)
				gradient.addColorStop(1, getColourValue("--teal-transparent"));

			gradients.push(gradient);
		});

		return gradients;
	};

	const generateDatasets = () => {
		//@ts-ignore
		const arrayToUse = [];
		props.datasets.forEach((x) => {
			arrayToUse.push({
				label: x.label,
				data: x.data,
				borderColor: getColourValue("--teal-500"),
				fill: {
					target: "start",
				},
				backgroundColor: isLineChart
					? generateLineGraphGradients
					: (context: ScriptableContext<"bar">) =>
							generateBarGraphGradients(context, x.data),
				pointHitRadius: props.pointHitRadius,
			});
		});

		//@ts-ignore
		return arrayToUse;
	};

	const getExaggeratedMin = () => {
		return props.min
			? props.min -
					props.min * (1 - clampNumber(props.yAxisExaggeration ?? 0, 0, 1))
			: undefined;
	};

	const [trackerState, setTrackerState] = useState<TrackerState>({
		x: 0,
		y: 0,
		value: 0,
		leftOffset: 0,
	});

	const isLineChart = props.type === "line";

	const data = useMemo(() => {
		return {
			labels: props.labels,
			datasets: props.loading ? [] : generateDatasets(),
		};
	}, [props.datasets, props.labels, props.loading]);

	const plugins = React.useMemo(
		() => [
			{
				id: "tracker",
				afterDraw(chart: Chart) {
					const scale: any = chart.scales["y"];
					const dataset = chart.data.datasets[0];

					if (scale && dataset && dataset.data.length > 0) {
						const lastValue = dataset.data.slice(-1)[0] as number;

						//To be used as the highest and lowest pixel values in the range of data values
						var minHeightOffset: number = scale.bottom;
						var maxHeightOffset = 0;

						//any is necessary here as otherwise translation cannot be seen and will error out,
						// no type exists to cast this to. Also note that y is being used as a forced variable
						// based on the second value in the translation array
						var tickMaxIndex = scale._labelItems.findIndex(
							({ translation: [, y] }: any, index: number) => {
								if (lastValue <= scale.ticks[index].value) return true;

								minHeightOffset = y;

								return false;
							},
						);

						if (scale._labelItems?.[tickMaxIndex]?.translation) {
							maxHeightOffset = scale._labelItems[tickMaxIndex].translation[1];
						}

						const tickMinIndex = Math.max(0, tickMaxIndex - 1);

						//Size of range in pixels
						const tickMinMaxHeightDifference =
							maxHeightOffset - minHeightOffset;

						//smallest value in range
						const tickMinValue = scale.ticks[tickMinIndex].value;

						//largest value in range
						const tickMaxValue = scale.ticks[tickMaxIndex].value;

						//find location of last displayed value as a percentage e.g. it is at .7(70%) of the range
						const lastValueHeightRatio =
							(lastValue - tickMinValue) / (tickMaxValue - tickMinValue || 1);

						//since the graph moves left to right this is necessary to ensure the arrow is accurate
						const leftPosition = scale._labelItems[tickMinIndex].translation[0];

						setTrackerState({
							x: leftPosition ?? 0,
							y:
								minHeightOffset +
								lastValueHeightRatio * tickMinMaxHeightDifference,
							value: lastValue,
							leftOffset: leftPosition - scale.left,
						});
					}
				},
			},
		],
		[],
	);

	const hasData = data.datasets.some((x) => x.data.length > 0);

	const options: ChartOptions = useMemo(
		() => ({
			animation: {
				duration: props.animationDuration,
			},
			responsive: true,
			scales: {
				x: {
					display: props.maxTicksLimit !== 0,
					grid: {
						display: !props.loading && isLineChart,
						drawBorder: isLineChart,
					},
					ticks: {
						display: !props.loading,
						autoSkip: props.autoSkip ?? undefined,
						font: {
							size: isLineChart ? 14 : 17,
						},
						maxTicksLimit: props.maxTicksLimit ?? undefined,
						maxRotation: props.xAxisMaxRotation ?? undefined,
						minRotation: props.xAxisMinRotation ?? undefined,
						includeBounds: false,
						callback: props.xAxisCallback,
					},
				},
				y: {
					min: getExaggeratedMin(),
					grid: {
						display: false,
						drawBorder: isLineChart,
					},
					ticks: {
						display: !props.loading && isLineChart,
						padding: 20,
						font: {
							size: 14,
						},
						callback: (value: number | string) => {
							return formatChartLabel(value, props.yAxisFormatOptions);
						},
					},
					stacked: true,
					position: "right" as const, // `axis` is determined by the position as `'y'`
				},
			},
			elements: {
				point: {
					radius: 0,
				},
			},
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: false,
				},
				title: {
					display: !!props.headerContent,
					text: props.headerContent,
				},
				filler: {
					propagate: true,
				},
				tracker: {
					enabled: props.liveTracker,
				},
				tooltip: {
					callbacks: {
						title: props.onRenderTooltipTitle,
						label: props.onRenderTooltipLabel,
					},
				},
			},
		}),
		[
			props.type,
			props.animationDuration,
			props.headerContent,
			props.liveTracker,
			props.min,
			props.maxTicksLimit,
			props.liveTrackerFormatOptions,
			props.xAxisMaxRotation,
			props.xAxisMinRotation,
			props.yAxisFormatOptions,
			props.onRenderTooltipLabel,
			props.onRenderTooltipTitle,
		],
	);

	const getChart = () => {
		switch (props.type) {
			case "bar":
				return <Bar options={options as ChartOptions<"bar">} data={data} />;
			default:
				return (
					<>
						{props.liveTracker && hasData && (
							<div className="graph-live-tracker relative">
								<div
									className="tracker-content flex absolute"
									style={
										{
											top: trackerState.y,
											left: trackerState.x,
											"--scale-offset": `${trackerState.leftOffset}px`,
										} as React.CSSProperties
									}
								>
									<div className="left-arrow" />
									<div className="value flex items-center">
										{formatChartLabel(
											trackerState.value,
											props.liveTrackerFormatOptions ?? undefined,
										)}
									</div>
								</div>
							</div>
						)}
						<Line
							options={options as ChartOptions<"line">}
							plugins={plugins}
							data={data}
						/>
					</>
				);
		}
	};

	const mainStyle = ["relative", props.className].join(" ");

	return (
		<div className={mainStyle}>
			{!hasData && (
				<div className="absolute top-1/2 left-1/2">
					<div className="flex h-[50px] ml-[-50%] mt-[-25px]">
						<div className="relative w-[50px]">
							<CustomLoader
								page={false}
								size="small"
								theme="light"
								thickness="thinner"
							/>
						</div>
						<div className="flex items-center justify-center font-primary-regular opacity-70">
							No data to display
						</div>
					</div>
				</div>
			)}
			{getChart()}
		</div>
	);
};
