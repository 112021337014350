import type { CycleListResponse, StatementResponse } from "@app/entities";
import { useGetClientCycles, useGetCycleStatement } from "@app/helpers";
import { useMediaQuery } from "@app/hooks/use-media-query";
import type { RootState } from "@app/redux";
import { downloadFile } from "@app/utils/download-file";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TradesView } from "./trades-view";

interface TradesState {
	currentPage: number;
	endDate: string;
	error?: string;
	showErrors: boolean;
	startDate: string;
}

const defaultState: TradesState = {
	currentPage: 1,
	endDate: "",
	showErrors: false,
	startDate: "",
};

const DEFAULT_PAGE_SIZE = 25;

const Trades = () => {
	const isMobile = useMediaQuery();
	const [getClientCycles] = useGetClientCycles();
	const [getCycleStatement] = useGetCycleStatement();

	const {
		activeClient,
		cycles,
		cyclesError,
		cyclesTotalCount,
		cyclesTotalPages,
		cyclesLoading,
		statementLoading,
	} = useSelector((rootState: RootState) => rootState.clients);

	const [state, setState] = useState<TradesState>(defaultState);
	const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

	const onChangeEndDate = (date: string) => {
		setState({ ...state, endDate: date });
	};

	const onChangeStartDate = (date: string) => {
		setState({ ...state, startDate: date });
	};

	const onClearErrors = () => {
		setState({ ...state, showErrors: false });
	};

	const onDownloadHistory = (format: string) => {
		if (activeClient?.onboardingComplete === false) {
			return;
		}

		const newState = state;

		getClientCycles(
			state.startDate ? moment(state.startDate).toDate() : undefined,
			state.endDate ? moment(state.endDate).toDate() : undefined,
			undefined,
			undefined,
			format,
			(
				response?: string[] | CycleListResponse | StatementResponse | undefined,
			) => {
				if (!response || "reasons" in response) {
					newState.showErrors = true;
				} else {
					const statement = response as StatementResponse;

					if (statement.contentType && statement.data && statement.fileName) {
						downloadFile(
							statement.contentType,
							statement.data,
							statement.fileName,
						);
					} else {
						setState({ ...state, showErrors: true });
					}
				}
			},
		);
		setState(newState);
	};

	const onDownloadStatement = (identifier: number) => {
		if (activeClient?.onboardingComplete === false) {
			return;
		}

		getCycleStatement(
			identifier,
			(response?: string[] | StatementResponse | undefined) => {
				const statement = response as StatementResponse;

				if (statement.contentType && statement.data && statement.fileName) {
					downloadFile(
						statement.contentType,
						statement.data,
						statement.fileName,
					);
				} else {
					setState({ ...state, showErrors: true });
				}
			},
		);
	};

	const onNavigatePage = (page: number) => {
		if (activeClient?.onboardingComplete === false) {
			return;
		}

		const newState = state;
		newState.currentPage = page;

		getClientCycles(
			state.startDate ? moment(state.startDate).toDate() : undefined,
			state.endDate ? moment(state.endDate).toDate() : undefined,
			pageSize,
			page,
			undefined,
			(
				response?: string[] | CycleListResponse | StatementResponse | undefined,
			) => {
				if (!response || "reasons" in response) {
					newState.showErrors = true;
				}
			},
		);
		setState(newState);
	};

	useEffect(() => {
		onNavigatePage(1);
	}, [state.startDate, state.endDate, pageSize]);

	return (
		<TradesView
			cycles={cycles}
			cyclesTotal={cyclesTotalCount}
			endDate={state.endDate}
			error={cyclesError?.join(" ")}
			loadingData={cyclesLoading}
			loadingPage={statementLoading}
			pageSize={pageSize}
			onPageSizeChange={setPageSize}
			page={state.currentPage}
			pageCount={cyclesTotalPages}
			showErrors={state.showErrors}
			startDate={state.startDate}
			onChangeEndDate={onChangeEndDate}
			onChangeStartDate={onChangeStartDate}
			onClearErrors={onClearErrors}
			onDownloadHistory={onDownloadHistory}
			onDownloadStatement={onDownloadStatement}
			onNavigatePage={onNavigatePage}
			isDesktop={!isMobile}
		/>
	);
};

export default Trades;
