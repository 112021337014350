import { Button } from "@app/controls/button";
import { AccordionCard } from "../accordion-card";
import "./client-accordion.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import type { ViewProperties } from "./properties";

export const ClientAccordionView = (props: ViewProperties) => {
	const childStyle = [
		"border-y-2",
		props.open && "-mb-0.5",
		"pb-3.5",
		"pt-3",
		"px-6",
		"client-accordion-panel",
	].join(" ");

	const getHeaderContent = () => {
		const clientName = props.clientName
			? `${props.clientName[0].toUpperCase()}${props.clientName.slice(1)} `
			: "";
		const clientSurname = props.clientSurname
			? `${props.clientSurname[0].toUpperCase()}${props.clientSurname.slice(1)}`
			: "";

		return (
			<div className="flex flex-row justify-between gap-x-2 font-primary-medium text-lg items-center px-6 py-6 client-accordion-header">
				<Button
					className="inline-block border-b-2 text-left min-w-min grow-0 client-accordion-heading client-accordion-label"
					theme="secondary"
					onClick={() => props.onViewClient(props.clientId)}
				>
					<div>
						{clientName}
						{clientSurname}
					</div>
				</Button>
				<div
					className="border px-2.5 py-2 client-accordion-chevron"
					onClick={() => props.onToggleOpen()}
				>
					{props.open ? (
						<FaChevronUp size="14px" className="client-accordion icon-filled" />
					) : (
						<FaChevronDown
							size="14px"
							className="client-accordion icon-filled"
						/>
					)}
				</div>
			</div>
		);
	};

	return (
		<AccordionCard headerContent={getHeaderContent()} open={props.open} locked>
			<div className={childStyle}>
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row justify-between gap-x-2">
						<div className="flex flex-row gap-x-2">
							<div className="font-primary-medium text-lg client-accordion-label shrink">
								Balance
							</div>
							{props.tooltipContent}
						</div>
						<div className="font-primary-regular text-lg shrink-0 client-accordion-figure">
							{props.fundsAvailable || "0"}
						</div>
					</div>
					<div className="flex flex-row justify-between gap-x-2">
						<div className="font-primary-medium text-lg client-accordion-label shrink">
							Min Return
						</div>
						<div className="font-primary-regular text-lg shrink-0 client-accordion-figure">
							{props.minReturn}
						</div>
					</div>
					<div className="flex flex-row justify-between gap-x-2">
						<div className="font-primary-medium text-lg client-accordion-label shrink">
							Allowance Available
						</div>
						<div className="font-primary-regular text-lg shrink-0 client-accordion-figure">
							{props.allowanceAvailable || "0"}
						</div>
					</div>
					<div className="flex flex-row justify-between gap-x-2">
						<div className="font-primary-medium text-lg client-accordion-label shrink">
							Total Profit
						</div>
						<div className="font-primary-regular text-lg shrink-0 client-accordion-figure">
							{props.totalProfit || "0"}
						</div>
					</div>
					<div className="flex flex-row justify-between gap-x-2">
						<div className="font-primary-medium text-lg client-accordion-label shrink">
							Trade Status
						</div>
						{props.statusContent}
					</div>
				</div>
			</div>
		</AccordionCard>
	);
};
